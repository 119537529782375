<script>
export default {
  props: {
    attrs: { default: () => ({}), type: Object },
    desc: String,
    error: String,
    label: String,
    required: Boolean,
    value: {},
  },
  computed: {
    id() {
      return `field-${this._uid}`;
    },
  },
};
</script>
