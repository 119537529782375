<template>
  <form class="bg-white rounded-8" @submit.prevent="handleSubmit">
    <div class="d-flex align-items-center justify px-24 py-16 border-bottom">
      <h2 class="fs-20 me-auto mb-0">Kelas</h2>
      <button
        type="submit"
        class="btn btn-primary"
        :class="{ 'is-loading': isSubmitting }"
        :disabled="isSubmitting"
      >
        Simpan
      </button>
    </div>

    <div class="p-24">
      <InputField
        label="Kelas"
        required
        v-model="input.name"
        :error="errors.name"
      />
    </div>
  </form>
</template>

<script>
import get from 'lodash/get';
import gradeRepository from '../../../api/repositories/gradeRepository';

import InputField from '../../form/InputField.vue';

export default {
  components: { InputField },

  props: ['grade'],

  data() {
    return { errors: {}, input: this.getInitialInput(), isSubmitting: false };
  },

  methods: {
    async handleSubmit() {
      try {
        this.errors = {};
        this.isSubmitting = true;

        if (this.input.id) {
          await gradeRepository.update(this.input);
          this.$alert.success('Kelas berhasil diubah');
        } else {
          await gradeRepository.store(this.input);
          this.$alert.success('Kelas berhasil dibuat', {
            onOK: () => this.$router.push('/admin/kelas'),
          });
        }

        this.$store.commit('grades/invalidate');
      } catch (error) {
        this.errors = get(error, 'response.data.error.errors', {});
        this.$alert.requestError(error);
      } finally {
        this.isSubmitting = false;
      }
    },

    getInitialInput() {
      const input = this.grade ? this.grade.$toJson() : {};
      return input;
    },
  },

  watch: {
    grade() {
      this.input = this.getInitialInput();
    },
  },
};
</script>
