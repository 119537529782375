<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <GradeForm :grade="grade" />
  </main>
</template>

<script>
import Grade from '../../../store/models/Grade';

import GradeForm from '../../../components/admin/grade/GradeForm.vue';

export default {
  components: { GradeForm },

  computed: {
    grade() {
      return Grade.query().withAll().find(this.$route.params.id);
    },
  },

  metaInfo() {
    return { title: this.grade && this.grade.name };
  },

  beforeMount() {
    if (!this.grade) {
      this.$store.dispatch('grades/maybeFetch', {
        filter: { id: this.$route.params.id },
      });
    }

    this.$store.commit('info/setBreadcrumb', [
      { path: '/admin/kelas', label: 'Kelas' },
    ]);
  },
};
</script>
